header {
  width: 100%;
  background: var(--white);
  z-index: 2;
}

nav p {
  /* background: rgb(201, 201, 201); */
  /* padding: var(--spacing-xs); */
  /* text-align: center; */
  margin-bottom: 0;
  font-weight: 800;
}

.main-container {
  background: var(--main-bg-color);
  max-width: 1140px;
}

section.main-content {
  padding-top: var(--spacing-xs);
  min-height: 100vh;
}

.registration {
  padding: var(--spacing-md) 0;
}

.registration > .main-section {
  max-width: 540px;
  margin: auto;
}

.main-section {
  box-shadow: 0 0 5px black;
  border-radius: 0.25rem;
  margin-bottom: var(--spacing-xl);
}

.main-section h2 {
  background: var(--section-header-bg);
  color: var(--section-header-text);
  padding-left: var(--spacing-sm);
}

.main-section h3 {
  text-align: left;
  text-decoration: underline;
}

.main-section-content {
  padding: var(--spacing-sm);
}

@media screen and (min-width: 580px) {
  .main-section-content {
    padding: var(--spacing-md);
  }
}

@media screen and (max-width: 768px) {
  nav {
    max-height: 100vh;
  }
  nav::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 992px) {
  header {
    position: fixed;
  }

  .navbar.fixed {
    position: fixed;
  }

  nav {
    overflow-y: scroll;
  }

  section.main-content {
    /* padding-top: var(--spacing-xl); */
    padding-top: calc(var(--spacing-xl) * 2);
  }
}
