.show-hidden-input {
  height: 100%;
  font-size: 12px;
  text-align: left;
  display: flex;
  align-items: center;
}

.show-hidden-input button {
  background: none;
  border: none;
  text-decoration: underline;
}

.address-2-input {
  visibility: hidden;
  display: none;
  /* margin-bottom: 2rem; */
}

.error-style {
  border: 1px solid red;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.field-error {
  color: red;
  padding-left: 5px;
}

.err-icon {
  font-size: 20px;
  color: red;
}

.input-group-err {
  border: 2px solid red;
  background: white;
}

.err-text {
  color: red;
}

@media screen and (min-width: 768px) {
  .show-hidden-input {
    font-size: inherit;
  }
}
