@import "./assets/css/layout.css";
@import "./assets/css/forms/forms.css";
@import "./assets/css/common.css";

body {
  background: gray;
  overflow-y: scroll;
}

:root {
  /* Theme  */
  --main-bg-color: whitesmoke;
  --section-header-bg: #0000c2;
  --section-header-text: white;
  --white: white;
  /* --disabled-header: rgba(0, 0, 0, 0.3); */
  --disabled-header: 0.4;

  /* Spacing  */
  --spacing-xs: 0.25rem;
  --spacing-sm: 0.5rem;
  --spacing-md: 1rem;
  --spacing-lg: 2rem;
  --spacing-xl: 3rem;
}

.delete-icon {
  color: red;
  font-size: 1.5rem;
}

.edit-table-item {
  color: darkgreen;
  font-weight: 700;
  font-size: 0.85rem;
  text-decoration: underline;
}

.phase-disabled {
  opacity: var(--disabled-header);
}

.icon-input {
  display: flex;
  position: relative;
}

.input-icon {
  position: absolute;
  right: 10px;
  height: 100%;
  /* margin-bottom: 0; */
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  text-decoration: underline;
}

/* .disabled {
  color: red;
} */

/* .active.nav-link {
  text-decoration: underline;
} */

.attention-section {
  box-shadow: 0 0 5px red;
}

.attention-section h3 {
  color: red;
}

/* .alert.alert-info {
  border-left: 5px solid #055160;
} */

.nav-link {
  /* background: rgb(255, 158, 158) !important; */
  /* color: white !important; */
  /* color: rgb(194, 0, 0) !important; */
  /* background: #38b1ce; */
  color: rgb(0, 0, 0) !important;
}

.nav-link.active {
  text-decoration: underline;
}

.nav-link.disabled {
  opacity: var(--disabled-header);
}

.in-progress {
  /* color: rgb(0, 81, 194) !important; */
  /* background: #f6a461; */
  background: #ffd3d3;
  color: rgb(87, 0, 0) !important;
}

.complete {
  background: #d1f2b4;
  color: #285500;
}

.req-input {
  font-size: 0.75rem;
}

.skip-this-step {
  background: lightgray;
  padding-left: var(--spacing-sm);
}

ul.office-cities {
  list-style-type: "- ";
  /* padding-left: 0; */
}

.loader-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 12px solid #0000c2;
  border-radius: 50%;
  border-top: 12px solid #f3f3f3;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* .animate #shakeMe {
  transform: scale(1.2);
} */

/* body.animate {
  overflow: hidden;
} */

.shakeMe.animate {
  animation: shake 0.25s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 100px;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.task-complete svg {
  color: green;
  font-size: 200px;
  box-shadow: 0px 0px 10px lightgray;
  margin-bottom: var(--spacing-md);
}
